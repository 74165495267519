import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { ConfirmPopup, Button } from "components"
import { websiteGeneralRemove } from "store/actions"

const RemoveWebsiteComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(websiteGeneralRemove.cleanState())
    }
  }, [])

  const removeModalRef = useRef(null)

  const handleRemove = () => {
    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }
  const resetRemove = () => {
    if (removeModalRef.current) {
      removeModalRef.current.hide()
    }
  }

  const confirmRemove = () => {
    dispatch(websiteGeneralRemove.remove())
  }

  return (
    <React.Fragment>
      <ConfirmPopup
        ref={removeModalRef}
        onConfirm={confirmRemove}
        onClosed={resetRemove}
      />
      <Button
        type={"button"}
        title={t("delete-website")}
        onClick={handleRemove}
        outline
      />
    </React.Fragment>
  )
}

RemoveWebsiteComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.website.general.remove
  return {
    loading,
    error,
  }
}

export const RemoveWebsite = connect(mapStateToProps)(RemoveWebsiteComponent)
