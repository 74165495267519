import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { LogoIcon } from "components";

export const Header = props => {
  const { t } = useTranslation()

  const { number } = props.data

  return (
    <div className="invoice-details-card__header">
      <div className="invoice-details-card__header-logo">
        <LogoIcon width={32} />
      </div>
      <h4 className="invoice-details-card__header-number mt-2">
        {t("invoice")} {number}
      </h4>
    </div>
  )
}

Header.propTypes = {
  data: PropTypes.object,
}
