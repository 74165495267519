import React from "react"
import PropTypes from "prop-types"

import variables from "assets/scss/dev/blocks/stripe/_stripe.module.scss"
import { StripeCardGroupFields } from "../StripeCardGroupFields"
import { StripeIbanField } from "../StripeIbanField"
import { BILLING_PAYMENT_METHOD_TYPE } from "consts"

const { CARD, SEPA_DEBIT } = BILLING_PAYMENT_METHOD_TYPE

// hotfix
const initFontSize = () => {
  return window.innerWidth < 768 ? "16px" : "14px"
}

export const StripeFormElement = ({ type, ...props }) => {
  const ELEMENT_STYLE = {
    // For more styling details, see https://stripe.com/docs/js/appendix/style
    base: {
      fontSize: initFontSize(),
      fontWeight: 400,
      color: variables.controlColor,
      fontFamily: "Nunito Sans, sans-serif",
      "::placeholder": {
        color: variables.placeholderColor,
      },
    },
    invalid: {
      color: variables.invalidColor,
    },
  }

  switch (type) {
    case CARD:
      return <StripeCardGroupFields elementStyle={ELEMENT_STYLE} {...props} />
    case SEPA_DEBIT:
      return <StripeIbanField elementStyle={ELEMENT_STYLE} {...props} />
    default:
      return null
  }
}

StripeFormElement.propTypes = {
  type: PropTypes.string,
}
