import React from "react"
import PropTypes from "prop-types"

import logo from "../../assets/images/dev/logo.png"

export const Logo = ({ width = 56 }) => (
  <img width={width} src={logo} alt={"logo"} />
)

Logo.propTypes = {
  width: PropTypes.number,
}
