import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import {
  IconButton,
  TooltipWrapper,
  Button,
  SubscriptionAccessAlert,
  ConfirmPopup,
} from "components"
import { useConfirmModal, useUserAccess } from "hooks"
import { PriorityMenu } from "./PriorityMenu"
import { EVENT_TYPE_CASTING } from "consts"

const priorities = new Array(15).fill(0).map((_, i) => i + 1)

export const PriorityControl = ({ loading, ...props }) => {
  const { t } = useTranslation()

  const { subscriptionFullAccessAllowed } = useUserAccess()
  const subscriptionAllowed = subscriptionFullAccessAllowed()

  const domElementId = `event-model-action-priority-${props.modelId}`

  const subForbidden = useMemo(() => {
    return props.eventType === EVENT_TYPE_CASTING && !subscriptionAllowed
  }, [props.eventType, subscriptionAllowed])

  const changePriority = priority => {
    const value = priority !== props.priority ? priority : null
    const payload = { priority: value }

    if (props.eventId) {
      showModal(payload)
    } else {
      props.onChange(payload)
    }
  }

  const onPrioritySubmit = payload => {
    props.onSubmit("priority", payload)
  }

  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    data => onPrioritySubmit(data),
    () => {}
  )

  return (
    <>
      <TooltipWrapper
        target={domElementId}
        content={
          <>
            {t("priority")}
            {props.priority && `: ${props.priority}`}
          </>
        }
      >
        <IconButton
          id={domElementId}
          onClick={() => {}}
          wrapClass={"text-success font-size-20"}
          icon={"bx bxs-up-arrow-circle"}
        />

        <PriorityMenu id={domElementId} placement={"bottom"}>
          <div className={"order-btn-wrap"}>
            {priorities.map((item, i) => (
              <Button
                btnClass={classNames("order-btn btn-sm", {
                  active: item === props.priority,
                })}
                key={i}
                type={"button"}
                disabled={loading || subForbidden}
                onClick={() => changePriority(item)}
                title={item.toString()}
                color="secondary"
                outline
              />
            ))}
          </div>

          {subForbidden && (
            <div className={"mt-1"}>
              <SubscriptionAccessAlert type={"text"} />
            </div>
          )}
        </PriorityMenu>
      </TooltipWrapper>

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
        questionText={"confirm-sure"}
        strapModalProp={{ zIndex: 1100 }}
      />
    </>
  )
}

PriorityControl.propTypes = {
  eventType: PropTypes.string,
  eventId: PropTypes.number,
  modelId: PropTypes.number,
  priority: PropTypes.number,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
}
