import React from "react"
import { useTranslation } from "react-i18next"

import { Button, Popover } from "components"
import { usePopover } from "hooks"

export const InstallApp = () => {
  const { t } = useTranslation()

  const { popoverId, toggle, popoverOpen } = usePopover({
    id: "btn-install-app",
  })

  return (
    <div>
      <Button
        title={"btn.install_app"}
        id={popoverId}
        color={"burgundy"}
        outline
      />

      <Popover
        popoverOpen={popoverOpen}
        toggle={toggle}
        popoverId={popoverId}
        placement={"bottom-start"}
      >
        {t("install_app.info")}
      </Popover>
    </div>
  )
}

InstallApp.propTypes = {}
