import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { Button, ConfirmPopup } from "../../../../../components"
import {
  modelPanelDeleteModel,
  modelPanelDeleteModelCleanState,
} from "../../../../../store/actions"

const DeleteModelComponent = props => {
  const { t } = useTranslation()
  const history = useHistory()

  const modalRef = useRef(null)

  useEffect(() => {
    return () => {
      props.modelPanelDeleteModelCleanState()
    }
  }, [])

  const handleDelete = e => {
    e.preventDefault()

    if (props.loading) {
      return
    }

    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const handleConfirm = () => {
    props.modelPanelDeleteModel(props.model.id, history)
  }

  return (
    <React.Fragment>
      <Button
        title={"btn.delete"}
        outline
        size={"btn-sm"}
        onClick={handleDelete}
        loading={props.loading}
      />

      <ConfirmPopup ref={modalRef} onConfirm={handleConfirm} />
    </React.Fragment>
  )
}

DeleteModelComponent.propTypes = {
  model: PropTypes.object,
  loading: PropTypes.bool,

  modelPanelDeleteModel: PropTypes.func,
  modelPanelDeleteModelCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { loading } = state.modelPanel.panel.remove
  return {
    loading,
    model: data,
  }
}

const mapDispatchToProps = {
  modelPanelDeleteModel,
  modelPanelDeleteModelCleanState,
}

export const DeleteModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteModelComponent)
