import {
  CASTINGS_AND_JOB,
  CLIENTS_CASTINGS,
  CLIENTS_EARNINGS,
  CLIENTS_JOBS,
  COMPARISON_BY_EARNINGS,
  EARNED_MONEY,
  EMPLOYEES_CASTINGS,
  EMPLOYEES_EARNINGS,
  EMPLOYEES_JOBS,
  MODELS_CASTINGS,
  MODELS_EARNINGS,
  MODELS_JOBS,
  TOP_TEN_CLIENTS_BY_EARNINGS,
  TOP_TEN_CLIENTS_BY_RATING,
  TOP_TEN_EMPLOYEES_BY_EARNINGS,
  TOP_TEN_EMPLOYEES_BY_RATING,
  TOP_TEN_MODELS_BY_EARNINGS,
  TOP_TEN_MODELS_BY_RATING,
} from "consts"
import variables from "assets/scss/giulia-variables.module.scss"
import i18n from "i18next"

export const generateCustomOptions = graphAlias => {
  switch (graphAlias) {
    case TOP_TEN_CLIENTS_BY_RATING: {
      return {
        colors: [variables.secondaryColor],
      }
    }
    case TOP_TEN_MODELS_BY_RATING: {
      return {
        colors: [variables.primaryColor],
      }
    }
    case TOP_TEN_EMPLOYEES_BY_RATING: {
      return {
        colors: [variables.secondaryColor],
      }
    }
    case TOP_TEN_CLIENTS_BY_EARNINGS: {
      return {
        colors: [variables.secondaryColor],
      }
    }
    case TOP_TEN_MODELS_BY_EARNINGS: {
      return {
        colors: [variables.primaryColor],
      }
    }
    case TOP_TEN_EMPLOYEES_BY_EARNINGS: {
      return {
        colors: [variables.secondaryColor],
      }
    }
    case EMPLOYEES_EARNINGS:
    case CLIENTS_EARNINGS:
    case MODELS_EARNINGS: {
      return {
        colors: [variables.secondaryColor],
      }
    }

    case MODELS_JOBS:
    case EMPLOYEES_JOBS:
    case CLIENTS_JOBS: {
      return {
        colors: [variables.primaryColor],
      }
    }
    case MODELS_CASTINGS:
    case EMPLOYEES_CASTINGS:
    case CLIENTS_CASTINGS: {
      return {
        colors: [variables.eventCastingColor],
      }
    }

    case COMPARISON_BY_EARNINGS: {
      return {
        baseChart: {
          colors: [variables.primaryColor],
        },
        comparisonChart: {
          colors: [variables.secondaryColor],
        },
      }
    }
    case CASTINGS_AND_JOB: {
      return {
        colors: [variables.primaryColor, variables.secondaryColor],
      }
    }
    case EARNED_MONEY: {
      return {
        colors: [
          variables.primaryColor,
          variables.secondaryColor,
          variables.secondaryDarkColor,
        ],
      }
    }
  }
}

export const noDataOptions = (loading, error) => {
  return {
    text: loading ? i18n.t("loading") : error || i18n.t("no-data-for-period"),
    style: {
      color: error && variables.dangerColor,
      fontSize: "1rem"
    },
  }
}
