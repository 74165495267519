import React from "react"
import { LogoIcon } from "components"

export const AuthLogo = () => {
  return (
    <div className={"d-flex justify-content-center mb-4"}>
      <LogoIcon width={130} />
    </div>
  )
}

AuthLogo.propTypes = {}
