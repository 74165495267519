import React, { useState } from "react"
import s from "./AiChat.module.scss"
import { Popover, PopoverHeader, PopoverBody } from "reactstrap"
import classNames from "classnames"
import PropTypes from "prop-types"
import { LogoIcon } from "components"

const popoverId = "ai-chat-button"

const CloseIcon = ({ className }) => {
  return (
    <i
      className={classNames("mdi mdi-close font-size-20", {
        [className]: className,
      })}
    />
  )
}

CloseIcon.propTypes = {
  className: PropTypes.string,
}

export const AiChat = ({ url }) => {
  const [visible, setVisible] = useState(false)

  const onToggle = () => {
    setVisible(prevState => !prevState)
  }

  return (
    <>
      <div className={s.root}>
        <button id={popoverId} className={s.button} onClick={() => onToggle()}>
          {visible ? (
            <CloseIcon className={"text-primary font-size-24"} />
          ) : (
            <LogoIcon width={46} />
          )}
        </button>

        <Popover
          popperClassName={classNames("popover-block", {
            [s.popoverRoot]: true,
          })}
          placement={"top-end"}
          target={popoverId}
          isOpen={visible}
          toggle={() => onToggle()}
          fade={false}
          flip={false}
          positionFixed
          innerClassName={s.popoverInner}
        >
          <PopoverHeader
            className={classNames("popover-block__header", {
              [s.popoverHeader]: true,
            })}
          >
            <div className={"d-flex align-items-center"}>
              <div className={"flex-fill"}>Giuli-IA</div>

              <div
                className={"cursor-pointer"}
                onClick={() => setVisible(false)}
              >
                <CloseIcon />
              </div>
            </div>
          </PopoverHeader>

          <PopoverBody
            className={classNames("popover-block__body", {
              [s.popoverBody]: true,
            })}
          >
            <iframe
              src={url}
              id="loginFrame"
              width={"100%"}
              height={"100%"}
            ></iframe>
          </PopoverBody>
        </Popover>
      </div>
    </>
  )
}

AiChat.propTypes = {
  url: PropTypes.string,
}
