import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { PageHeader, Button } from "components"
import { InstallApp } from "./InstallApp"

export const Header = ({ onSyncToCalendar }) => {
  const { t } = useTranslation()

  return (
    <div className={"panel-topbar"}>
      <PageHeader
        title={t("title.calendar")}
        headerRight={
          <div className={"d-flex gap-4 flex-wrap justify-content-end"}>
            <InstallApp />

            <Button
              color={"burgundy"}
              leftIcon={"bx bx-calendar"}
              outline
              title={"sync_to_calendar"}
              onClick={onSyncToCalendar}
            />
          </div>
        }
      />
    </div>
  )
}

Header.propTypes = {
  onSyncToCalendar: PropTypes.func,
}
