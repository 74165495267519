import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { Button, DateRangePickerField } from "components"
import { useEventsCalendarContext } from "contexts"

export const ComparePeriod = () => {
  const { t } = useTranslation()

  const [period, setPeriod] = useState([])

  const [startDate, endDate] = period

  const invalidPeriod = useMemo(() => {
    return !(startDate || endDate)
  }, [startDate, endDate])

  const {
    compare: { onComparePeriod },
  } = useEventsCalendarContext()

  const onCompare = () => {
    onComparePeriod?.(period)
  }

  const onPeriodChange = value => {
    setPeriod(value)
  }

  return (
    <div className={"d-flex gap-4 align-items-center flex-wrap"}>
      <Button
        outline
        title={"compare_period"}
        disabled={invalidPeriod}
        onClick={() => onCompare()}
      />

      <DateRangePickerField
        id={"period"}
        placeholder={t("select-period")}
        value={period}
        onChange={onPeriodChange}
      />
    </div>
  )
}

ComparePeriod.propTypes = {}
