import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button, Modal } from "../../../../../../../components"
import { websiteAddMenuItem } from "../../../../../../../store/actions"
import { ValidationsUtils } from "../../../../../../../utils"
import { CustomMenuItemForm } from "../CustomMenuItemForm"

const AddMenuItemComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const modalRef = useRef(null)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    return () => {
      if (modalVisible) {
        dispatch(websiteAddMenuItem.cleanState())
      }
    }
  }, [modalVisible])

  const handleAdd = e => {
    e.preventDefault()
    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.hide()
    }
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onAddSuccess()
    closeModal()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      websiteAddMenuItem.add({
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  return (
    <React.Fragment>
      <Button
        type={"button"}
        title={t("btn.new-menu-item")}
        leftIcon={"bx bx-plus "}
        onClick={handleAdd}
        outline
      />

      <Modal
        ref={modalRef}
        title={t("website.add-menu.title")}
        onVisibleChange={value => setModalVisible(value)}
        strapModalProp={{
          centered: true,
        }}
      >
        <CustomMenuItemForm
          loading={props.loading}
          error={props.error}
          onSubmit={handleSubmit}
          onCancel={closeModal}
        />
      </Modal>
    </React.Fragment>
  )
}

AddMenuItemComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  onAddSuccess: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.website.menuItems.add
  return {
    loading,
    error,
  }
}

export const AddMenuItem = connect(mapStateToProps)(AddMenuItemComponent)
