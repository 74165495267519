import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

export const InTownBadge = ({ status, labelMode }) => {
  const { t } = useTranslation()

  const colorPrefix = labelMode ? "text" : "bg"

  const config = {
    label: status ? "in-town.status.in" : "in-town.status.out",
    color: status ? `${colorPrefix}-success` : `${colorPrefix}-danger`,
  }

  return labelMode ? (
    <span className={config.color}>{t(config.label)}</span>
  ) : (
    <div
      className={classNames("badge-town", {
        [config.color]: true,
      })}
    >
      {t(config.label)}
    </div>
  )
}

InTownBadge.propTypes = {
  status: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  labelMode: PropTypes.bool,
}
