import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"
import { Button, Drawer } from "components"
import { requestHelper } from "helpers"
import {
  modelsPanelAddModel,
  modelsPanelAddModelCleanState,
} from "store/actions"
import { AddModelForm } from "pages/Models/components/ModelsPanelContainer/ModelsToolbar/DefaultControls/AddModel/AddModelForm"
import { ValidationsUtils } from "utils"

const AddModelComponent = props => {
  const { t } = useTranslation()

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [countryOptions, setCountryOptions] = useState([])
  const [measurementsOptions, setMeasurementsOptions] = useState({})

  useEffect(() => {
    return () => {
      props.modelsPanelAddModelCleanState()
    }
  }, [])

  useEffect(() => {
    if (drawerVisible) {
      fetchCountries()
      fetchMeasurementsOptions()
    }
    return () => {
      if (drawerVisible) {
        setCountryOptions([])
        setMeasurementsOptions({})
      }
    }
  }, [drawerVisible])

  const fetchCountries = async () => {
    try {
      const response = await requestHelper.staticData.getCountries({
        pagination: false,
      })
      const { data } = response

      setCountryOptions(data.data)
    } catch (e) {}
  }

  const fetchMeasurementsOptions = async () => {
    try {
      const response = await requestHelper.staticData.getMeasurementsOptions()
      const { data } = response

      setMeasurementsOptions(data.data)
    } catch (e) {}
  }

  const handleAdd = () => {
    setDrawerVisible(true)
  }

  const handleCloseDrawer = () => {
    setDrawerVisible(false)
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    props.onAddSuccess()
    handleCloseDrawer()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.modelsPanelAddModel(
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }

  return (
    <React.Fragment>
      <Button
        outline
        leftIcon={"bx bx-plus"}
        title={"btn.add-new-model"}
        onClick={handleAdd}
      />

      <Drawer
        title={t("add_new_model")}
        visible={drawerVisible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        drawerProps={{}}
      >
        <Container fluid>
          <Row>
            <AddModelForm
              initialValues={{
                booker_id: props.user,
              }}
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
              countryOptions={countryOptions}
              measurementsOptions={measurementsOptions}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

AddModelComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,

  onAddSuccess: PropTypes.func,

  modelsPanelAddModel: PropTypes.func,
  modelsPanelAddModelCleanState: PropTypes.func,

  user: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.models.panel.add
  return {
    loading,
    error,
    user: state.user.profile.data,
  }
}

const mapDispatchToProps = {
  modelsPanelAddModel,
  modelsPanelAddModelCleanState,
}

export const AddModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddModelComponent)
