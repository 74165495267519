import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Spinner } from "reactstrap"
import { IconButton, Button, Image } from "../../../../../../components"
import placeholder from "../../../../../../assets/images/dev/logo-website-placeholder.svg"

export const UploadAvatar = props => {
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const inputRef = useRef()

  const handleImageSelected = event => {
    const file = event.target.files[0]

    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setSelectedPhoto(reader.result)
      }
    }
    reader.readAsDataURL(file)

    props.onUpload(file)

    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const selectPhoto = event => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const removePhoto = () => {
    props.onRemove()
  }

  useEffect(() => {
    if (props.image) {
      setSelectedPhoto(null)
    }
  }, [props.image])

  return (
    <div className={"website-upload-avatar"}>
      <div className={"website-upload-avatar__block"}>
        <div className={"website-upload-avatar__select-block"}>
          <div className="avatar-select-wrap">
            <label htmlFor={"avatar"} className={"avatar-label"}>
              <div className="user-photo-avatar__block">
                <Image
                  src={
                    selectedPhoto
                      ? selectedPhoto
                      : props.image
                      ? props.image
                      : placeholder
                  }
                  alt="avatar"
                />
              </div>
            </label>
            <input
              type="file"
              id={"avatar"}
              className="d-none"
              onChange={handleImageSelected}
              ref={inputRef}
              accept="image/png, image/jpeg"
            />
            <div
              className={"avatar-loading"}
              style={{ display: props.loading ? "flex" : "none" }}
            >
              <Spinner size={"sm"} color="primary-green" />
            </div>
          </div>
        </div>

        <Button
          onClick={selectPhoto}
          title={"btn.upload-website-avatar"}
          disabled={props.loading}
          outline
        />
      </div>

      {props.image && (
        <div>
          <IconButton
            name={"remove"}
            onClick={removePhoto}
            bg
            disabled={props.loading}
          />
        </div>
      )}
    </div>
  )
}

UploadAvatar.propTypes = {
  image: PropTypes.any,
  loading: PropTypes.bool,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
}
