import React from "react"
import PropTypes from "prop-types"
import { Button } from "../Button"

export const ToggleButton = ({
  value,
  trueTitle = "True Title",
  falseTitle = "False Title",
  disabled,
  onClick,
  ...props
}) => {
  const handleClick = () => {
    onClick()
  }
  const renderTitle = value => {
    return value ? trueTitle : falseTitle
  }
  const renderColor = value => {
    return value ? "primary" : "success"
  }

  return (
    <Button
      type={"button"}
      style={{ minWidth: "100px" }}
      color={renderColor(value)}
      title={renderTitle(value)}
      onClick={handleClick}
      disabled={disabled}
      outline
      {...props}
    />
  )
}

ToggleButton.propTypes = {
  value: PropTypes.any,
  trueTitle: PropTypes.string,
  falseTitle: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
