import React, { useEffect, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import {
  modelPanelPhotosListRemovePhotos,
  modelPanelPhotosListRemovePhotosCleanState,
} from "store/actions"
import { ConfirmPopup, TextButton } from "components"

const DeletePhotosComponent = props => {
  const { t } = useTranslation()

  const removeModalRef = useRef(null)

  useEffect(() => {
    return () => {
      props.modelPanelPhotosListRemovePhotosCleanState()
    }
  }, [])

  const confirmRemove = () => {
    props.modelPanelPhotosListRemovePhotos(
      props.selected,
      props.onDeleteSuccess
    )
  }

  const handleDelete = () => {
    if (removeModalRef.current) {
      removeModalRef.current.show()
    }
  }

  const count = useMemo(() => {
    return props.selected.length
  }, [props.selected])

  return (
    <React.Fragment>
      <TextButton
        title={"btn.delete"}
        onClick={handleDelete}
        titlePostfix={count ? `(${count})` : null}
      />
      <ConfirmPopup ref={removeModalRef} onConfirm={confirmRemove}>
        <p className={"font-size-22"}>{t("sure_delete_photos")}?</p>
      </ConfirmPopup>
    </React.Fragment>
  )
}

DeletePhotosComponent.propTypes = {
  selected: PropTypes.array,
  onDeleteSuccess: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,

  modelPanelPhotosListRemovePhotos: PropTypes.func,
  modelPanelPhotosListRemovePhotosCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.modelPanel.photos.list.removePhotos
  return {
    loading,
    error,
  }
}

const mapDispatchToProps = {
  modelPanelPhotosListRemovePhotos,
  modelPanelPhotosListRemovePhotosCleanState,
}

export const DeletePhotos = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeletePhotosComponent)
