import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { useHistory, useRouteMatch } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"

import { Button, PageHeader, ConfirmPopup, TabNavigator } from "components"
import {
  packageFetchList,
  packageListSetPage,
  packageFetchListCleanState,
  packageListRemoveItem,
  packageListRemoveItemCleanState,
} from "store/actions"
import { PackagesListContainer } from "./PackagesListContainer"
import { StatisticsContainer } from "./StatisticsContainer"
import { PackageViewDrawer } from "./PackageViewDrawer"
import { useConfirmModal, useDrawer } from "hooks"

const TAB = {
  GENERAL: "general",
  STATISTIC: "statistics",
}

const PackagesContainerComponent = props => {
  const { t } = useTranslation()
  const history = useHistory()
  let { path } = useRouteMatch()

  useEffect(() => {
    return () => {
      props.packageListRemoveItemCleanState()
    }
  }, [])

  const [syncCount, forceUpdate] = React.useReducer(x => x + 1, 0)
  const syncData = () => {
    forceUpdate()
  }

  // TABS
  const [activeTab, setActiveTab] = useState(TAB.GENERAL)

  const onTabChange = tab => {
    setActiveTab(tab)
  }
  // === //

  const onEdit = item => {
    const { id } = item
    history.push(`${path}/${id}/edit`)
  }

  // Remove //
  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    item => {
      props.packageListRemoveItem(item.id, syncData)
    },
    () => {}
  )
  // ==== //

  // View //
  const {
    hide: hideViewDrawer,
    show: showViewDrawer,
    visible: viewDrawerVisible,
    data: viewDrawerData,
    resetData: resetViewDrawerData,
  } = useDrawer()
  // ==== //

  return (
    <React.Fragment>
      <PageHeader
        title={t("title.packages")}
        headerRight={
          <>
            <Button
              outline
              leftIcon={"bx bx-plus"}
              title={"btn.create-new-package"}
              onClick={() => history.push(`${path}/add`)}
            />
          </>
        }
      />

      <Row>
        <Col lg="12">
          <Card>
            <CardBody className={"bg-neutral"}>
              <TabNavigator
                activeTab={activeTab}
                onTabChange={onTabChange}
                destroyOnClose={true}
                tabBarType={"nav"}
                tabs={[
                  {
                    label: t("general"),
                    value: TAB.GENERAL,
                    render: () => (
                      <PackagesListContainer
                        syncCount={syncCount}
                        onPackageView={showViewDrawer}
                        onPackageRemove={showModal}
                        onPackageEdit={onEdit}
                      />
                    ),
                  },
                  {
                    label: t("statistics"),
                    value: TAB.STATISTIC,
                    render: () => (
                      <StatisticsContainer
                        syncCount={syncCount}
                        onPackageView={showViewDrawer}
                      />
                    ),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <PackageViewDrawer
        visible={viewDrawerVisible}
        id={viewDrawerData?.id}
        onClose={hideViewDrawer}
        onDestroy={resetViewDrawerData}
      />

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
        questionText={t("sure_delete_package")}
      />
    </React.Fragment>
  )
}

PackagesContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,

  packageFetchList: PropTypes.func,
  packageListSetPage: PropTypes.func,
  packageFetchListCleanState: PropTypes.func,
  packageListRemoveItem: PropTypes.func,
  packageListRemoveItemCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page } = state.package.list.data
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
  }
}

const mapDispatchToProps = {
  packageFetchList,
  packageListSetPage,
  packageFetchListCleanState,
  packageListRemoveItem,
  packageListRemoveItemCleanState,
}

export const PackagesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackagesContainerComponent)
