import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { CheckboxGroup, SearchInput } from "components"
import { NormalizeUtils } from "utils"
import i18n from "i18n"
import { Col } from "reactstrap"

const isInTownOptions = [
  {
    label: (
      <span className={"text-success"}>{i18n.t("in-town.status.in")}</span>
    ),
    value: true,
    color: "secondary",
  },
  {
    label: (
      <span className={"text-danger"}>{i18n.t("in-town.status.out")}</span>
    ),
    value: false,
    color: "secondary",
  },
]

export const ModelsFilters = props => {
  const { t } = useTranslation()
  const [searchInputValue, setSearchInputValue] = useState("")

  const onSearchInputChange = value => {
    setSearchInputValue(value)
  }

  const handleSearch = value => {
    props.onSearch(value)
  }

  const inTownChange = value => {
    props.onInTownChange(value)
  }

  return (
    <>
      <Col className={"d-flex-column"}>
        <CheckboxGroup
          id={`${props.alias}-is_in_town`}
          onChange={inTownChange}
          fieldValue={props.filters.values?.is_in_town || []}
          options={isInTownOptions}
          normalize={NormalizeUtils.stringBoolToBoolean}
          className={"d-flex form-checkbox-group_horizontal mb-2"}
        />
        <SearchInput
          value={searchInputValue}
          onInputChange={onSearchInputChange}
          onSearch={handleSearch}
          placeholder={t("start_typing")}
        />
      </Col>
    </>
  )
}

ModelsFilters.propTypes = {
  alias: PropTypes.string,
  filters: PropTypes.object,
  onSearch: PropTypes.func,
  onInTownChange: PropTypes.func,
  is_in_town: PropTypes.any,
}
