import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import { ConfirmPopup } from "../../../../ConfirmPopup"
import { calendarEventRemove } from "../../../../../store/actions"
import { Button } from "../../../../Buttons"

const EventDeleteComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const modalRef = useRef(null)

  useEffect(() => {
    return () => {
      dispatch(calendarEventRemove.cleanState())
    }
  }, [])

  const handleDelete = () => {
    if (modalRef.current) {
      modalRef.current.show()
    }
  }

  const onSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess({ id: props.id })
    }
  }

  const handleConfirm = () => {
    dispatch(calendarEventRemove.remove({ id: props.id, onSuccess }))
  }

  return (
    <React.Fragment>
      <Button
        title={"btn.delete"}
        onClick={handleDelete}
        outline
        loading={props.loading}
        disabled={props.loading}
        btnClass={"w-100"}
      />

      <ConfirmPopup ref={modalRef} onConfirm={handleConfirm} />
    </React.Fragment>
  )
}

EventDeleteComponent.propTypes = {
  id: PropTypes.number,
  onSuccess: PropTypes.func,

  loading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { loading } = state.calendarEvent.remove
  return {
    loading,
  }
}

export const EventDelete = connect(mapStateToProps)(EventDeleteComponent)
