import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, ConfirmPopup } from "components"
import { useConfirmModal, usePostsDrawerManager } from "hooks"
import { selectSocialCalendarRemovePost, socialCalendarRemovePost } from "store"

export const PostDelete = () => {
  const dispatch = useDispatch()

  const {
    closeDrawer,
    state: { postData },
  } = usePostsDrawerManager()
  const { loading } = useSelector(selectSocialCalendarRemovePost)
  const { closeModal, showModal, modalRef, handleConfirm } = useConfirmModal(
    () => {
      dispatch(
        socialCalendarRemovePost.remove({
          id: postData.id,
          onSuccess: closeDrawer,
        })
      )
    },
    () => dispatch(socialCalendarRemovePost.cleanState())
  )

  return (
    <React.Fragment>
      <Button
        title={"btn.delete"}
        onClick={showModal}
        outline
        btnClass={"w-100"}
        disabled={loading}
        loading={loading}
      />
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
        questionText={"remove-post.confirm"}
      />
    </React.Fragment>
  )
}

PostDelete.propTypes = {}
