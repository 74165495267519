import React from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { modelsPanelState } from "store"

import { Button, ImportEntityModalButtonControl } from "components"
import { AddModel } from "./AddModel"
import { IMPORT_ENTITY_TYPE } from "consts"

export const DefaultControls = ({ fetchData, group }) => {
  const dispatch = useDispatch()

  const handleCreateGroup = () => {
    dispatch(modelsPanelState.setCreateGroupState())
  }

  const handleEditGroup = () => {
    dispatch(modelsPanelState.setEditGroupState())
  }

  return (
    <>
      <ImportEntityModalButtonControl
        type={IMPORT_ENTITY_TYPE.MODELS}
        btnClass={"me-2"}
      />

      {group && (
        <Button
          outline
          title={"edit-group"}
          btnClass={"mr-2"}
          onClick={handleEditGroup}
        />
      )}
      <Button
        outline
        title={"create-group"}
        btnClass={"mr-2"}
        onClick={handleCreateGroup}
      />
      <AddModel onAddSuccess={fetchData} />
    </>
  )
}

DefaultControls.propTypes = {
  fetchData: PropTypes.func,
  group: PropTypes.number,
}
