import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "store/actions"
import { LogoIcon } from "../LogoIcon"
import { SendUserIdea } from "components/SendUserIdea"
import { ProductTour } from "./ProductTour"

const Header = props => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to={`/calendar`} className="logo logo-light">
                <span className="logo-sm">
                  <LogoIcon width={40} />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={event => {
                event.stopPropagation()
                props.onSidebarToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex align-items-center">
            <ProductTour btnWrapClass={"mx-3 mb-1"} />
            <SendUserIdea btnWrapClass={"mx-3 mb-1"} />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,

  onSidebarToggle: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
