import React from "react"
import PropTypes from "prop-types"
import { Button, TextButton } from "components"
import { useDispatch } from "react-redux"
import { modelsAddGroup, modelsPanelState } from "store"
import { CreateGroup } from "./CreateGroup"
import { useModal } from "hooks"
import { useTranslation } from "react-i18next"

export const CreateGroupControls = ({ selectedModels, onModelsDataChange }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { modalRef, closeModal, showModal, onModalClose } = useModal(() => {
    dispatch(modelsAddGroup.cleanState())
    dispatch(modelsPanelState.setDefaultState())
    onModelsDataChange()
  })

  const renderSaveTitle = () => {
    return `${t("save")} (${selectedModels.length})`
  }

  const handleCreateGroupSave = () => {
    showModal()
  }
  const handleCreateGroupCancel = () => {
    dispatch(modelsPanelState.setDefaultState())
  }

  return (
    <>
      <Button
        outline
        renderTitle={renderSaveTitle()}
        title={"save"}
        btnClass={"mr-2"}
        onClick={handleCreateGroupSave}
      />
      <TextButton
        btnClass={"mr-2"}
        title={"cancel"}
        onClick={handleCreateGroupCancel}
      />
      <CreateGroup
        modalRef={modalRef}
        onAddSuccess={onModalClose}
        onCancel={closeModal}
        selectedModels={selectedModels}
        onModalClose={onModalClose}
      />
    </>
  )
}

CreateGroupControls.propTypes = {
  selectedModels: PropTypes.array,
  onModelsDataChange: PropTypes.func,
}
