import React, { useRef, useEffect, useState } from 'react';
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from '../../../../components';
import { CreateEmployeeForm } from './CreateEmployeeForm';
import { employeeCreate, employeeCreateCleanState } from '../../../../store/actions';

const CreateEmployeeComponent = (props) => {
    const { t } = useTranslation();

    const modalRef = useRef(null);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        return () => {
            props.employeeCreateCleanState();
        }
    }, []);

    const handleCreate = () => {
        if (modalRef.current) {
            modalRef.current.show();
        }
    };

    const handleCancel = () => {
        if (modalRef.current) {
            modalRef.current.hide();
        }
    }

    const onCreateSuccess = (onSubmitProps) => () => {
        const { setSubmitting, resetForm } = onSubmitProps;
        setSubmitting(false);
        resetForm();

        if (modalRef.current) {
            modalRef.current.hide();
        }

        props.onCreate();
    }

    const onCreateError = (onSubmitProps) => (errors) => {
        const { setErrors, setSubmitting } = onSubmitProps;
        setSubmitting(false);
        setErrors(errors);
    }

    const handleSubmit = (values, onSubmitProps) => {
        props.employeeCreate(values, onCreateSuccess(onSubmitProps), onCreateError(onSubmitProps))
    }

    return (
        <React.Fragment>
            <Button
                leftIcon={'bx bx-plus'}
                title={'btn.create-new'}
                onClick={handleCreate}
                outline
            />

            <Modal
                ref={modalRef}
                title={t('create_new_employee')}
                onVisibleChange={(value) => setModalVisible(value)}
                strapModalProp={{
                    centered: false,
                    unmountOnClose: false,
                }}
            >

                <CreateEmployeeForm
                    loading={props.loading}
                    error={props.error}
                    initialValues={{
                        email: '',
                        name: '',
                        department: null,
                    }}
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                />

            </Modal>
        </React.Fragment>
    );
};

CreateEmployeeComponent.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,

    onCreate: PropTypes.func,

    employeeCreate: PropTypes.func,
    employeeCreateCleanState: PropTypes.func,
}

const mapStateToProps = state => {
    const { loading, error } = state.employee.create;
    return {
        loading,
        error,
    };
};

const mapDispatchToProps = {
    employeeCreate,
    employeeCreateCleanState,
};

export const CreateEmployee = connect(mapStateToProps, mapDispatchToProps)(CreateEmployeeComponent);